import _ from 'lodash'
import { navigate } from 'gatsby'

export default class Window {
  /**
        Allow any part of the app to signal to the user using the `notistack`.
        Relies on an `alertFn`, set at runtime in `src/pages/page.js`.
    */
  static alert(msg, args) {
    if (_.isFunction(Window.alertFn)) Window.alertFn(msg, args)
    else if (typeof window !== 'undefined')
      // eslint-disable-next-line no-alert
      window.alert(msg)
  }

  static confirm(msg) {
    if (_.isFunction(Window.confirmFn)) Window.confirmFn(msg)
    else if (typeof window !== 'undefined')
      Promise((resolve, reject) => {
        // eslint-disable-next-line no-alert
        if (window.confirm(msg)) resolve(true)
        reject(false)
      })
  }

  static error(msg) {
    if (_.isFunction(Window.alertFn))
      Window.alertFn(msg, {
        variant: 'error',
        persist: true,
      })
    else if (typeof window !== 'undefined')
      // eslint-disable-next-line no-alert
      window.alert(msg)
  }

  static fn(...path) {
    if (typeof window === 'undefined') return () => {}
    return _.get(window, ...path)
  }

  static get(...path) {
    if (typeof window === 'undefined') return {}
    if (_.isEmpty(path)) return window
    return _.get(window, ...path)
  }

  static navigate(...args) {
    if (typeof window === 'undefined') return null
    return navigate(...args)
  }

  static newTab(link) {
    if (typeof window === 'undefined') return null
    window.open(link, '_blank')
  }

  static prompt(msg, currentValue) {
    // if (_.isFunction(Window.promptFn)) return Window.promptFn(msg)
    if (typeof window !== 'undefined') {
      const response = window.prompt(msg, currentValue)
      return new Promise(resolve => resolve(response))
    }
  }
}
