import _ from 'lodash'

/**
    @public Global Constants 
*/
export const FONTS = {
  primary: 'Rubik',
  secondary: 'Karla',
}

export const APP_TITLE = 'Made to Uplift | Empowering Communities'
export const EMAIL = 'info@madetouplift.com'
export const HEADER_HEIGHT = 80

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})
export function formatNumber(amount, options = {}) {
  if (options.includeDecimal && options.isCurrency)
    return currencyFormatter.format(amount)

  const plusOrMinus = amount < 0 ? '-' : ''
  const dollarSign = options.isCurrency ? '$' : ''
  const formatted = Math.abs(_.toNumber(amount)).toLocaleString(undefined, {
    maximumFractionDigits: options.includeDecimal ? 2 : 0,
  })
  const suffix = options.isPercentage ? '%' : ''

  return `${plusOrMinus}${dollarSign}${formatted}${suffix}`
}
