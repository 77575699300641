import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { SnackbarProvider } from 'notistack'

import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Button from '@material-ui/core/Button'

import { APP_TITLE } from '../utils/core'
import Analytics from '../backend/analytics'
import wrapperStyle from '../styles/wrapper'

function ElevationScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

export default function Wrapper({ children }) {
  const classes = wrapperStyle()
  const notistackRef = React.createRef()

  React.useEffect(() => {
    Analytics.init()
  }, [])

  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <div className={classes.app}>
      <Helmet>
        <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
        <meta
          name="facebook-domain-verification"
          content="f2pqvrfs0t8tap047x0opm2kjkobsa"
        />

        <title>{APP_TITLE}</title>

        <script src="https://js.stripe.com/v3/"></script>
      </Helmet>

      <SnackbarProvider
        ref={notistackRef}
        autoHideDuration={3000}
        disableWindowBlurListener
        maxSnack={2}
        preventDuplicate
        action={key => <Button onClick={onClickDismiss(key)}>Dismiss</Button>}
      >
        {children}
      </SnackbarProvider>
    </div>
  )
}

Wrapper.propTypes = {
  children: PropTypes.element.isRequired,
}
