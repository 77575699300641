import _ from 'lodash'

const TEST_MODE = 'test'
const LIVE_MODE = 'live'

class Backend {
  static get backendURL() {
    // Developer runs "yarn local"
    if (process.env.GATSBY_BACKEND === 'local') return 'http://localhost:5000/'
    return 'https://api.madetouplift.com/'
  }

  static get inDevMode() {
    return (
      (typeof process !== 'undefined' &&
        typeof process.env !== 'undefined' &&
        process.env.NODE_ENV === 'development') ||
      Backend.stripeMode === TEST_MODE
    )
  }

  static get stripeMode() {
    // Developer runs "yarn local"
    if (process.env.GATSBY_BACKEND === 'local') return TEST_MODE
    // Developer is on localhost (e.g., yarn start)
    if (
      typeof window !== 'undefined' &&
      _.get(window, ['location', 'href'], '').includes('http://localhost')
    )
      return TEST_MODE
    // External person is testing site
    if (
      typeof window !== 'undefined' &&
      _.get(window, ['location', 'href'], '').includes(
        'https://test.madetouplift.com'
      )
    )
      return TEST_MODE
    return LIVE_MODE
  }

  static get stripeKey() {
    if (Backend.stripeMode === TEST_MODE)
      return 'pk_test_51Id8ODBF9PMv2g3b6xDoImM6lCeGng4BLYFI2LDKagRMlJHh8vt22diMfIieFoEpCnD7mQYnSMzo6JlZnrNnYeBD00QDWswiVh'
    return 'pk_live_51Id8ODBF9PMv2g3bFExaczO8KdDGsiZ7JYAINpSRwVkXFuDPtdmmCK7au5H9ayevnxGKKCtPLOInl6KDiUoKxeKY006xWv4qld'
  }
}

export default Backend
