import _ from 'lodash'
import { local } from 'brownies'

import Backend from './backend'
import Logger from '../utils/logger'
import REST from '../utils/rest'
import Window from '../utils/window'

const PIXEL_ID = '1068095340672511'
let PIXEL_STATUS = null // Not initialized

export default class Analytics {
  static init() {
    // Cookies
    Analytics.userIdentifier()

    // Setup FB pixel
    Analytics.loadBasePixelCode()
    Analytics.fbq('set', 'autoConfig', false, PIXEL_ID)
    Analytics.fbq('init', PIXEL_ID)
    Analytics.checkPixelStatus()
  }

  static loadBasePixelCode() {
    /* eslint-disable */
    !(function(f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function() {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    )
    /* eslint-enable */
  }

  static checkPixelStatus() {
    const notifyStatus = () =>
      Logger.debug(`FB Pixel Active: ${PIXEL_STATUS}`, 'analytics')

    const script = document.createElement('script')
    document.body.appendChild(script)
    script.onload = () => {
      PIXEL_STATUS = true
      notifyStatus()
    }
    script.onerror = () => {
      PIXEL_STATUS = false
      notifyStatus()
    }
    script.async = true
    script.src = 'https://connect.facebook.net/en_US/fbevents.js'
  }

  static fbq(call, ...args) {
    const event = args[0]
    const fbq = Window.get('fbq')
    let msg = null

    if (Backend.inDevMode) {
      msg = `Would've fired FB Pixel event (${event}) but on test mode`
    } else if (_.isFunction(fbq)) {
      msg = `Fired FB Pixel event: ${event}`
      fbq(call, ...args)
    } else {
      msg = `Would've fired FB Pixel event (${event}) but pixel is blocked`
    }

    if (event !== 'autoConfig' && event !== PIXEL_ID)
      Logger.debug(msg, 'analytics')
  }

  static userIdentifier() {
    if (local.user === null) {
      local.user = `${_.now()}_${_.random(100 * 1000)}`
      Logger.debug(`New user. Assigning UID: ${local.user}`, 'analytics')
    } else Logger.debug(`Existing user found. UID: ${local.user}`, 'analytics')
    return local.user
  }

  static fireBackendTracking(eventName, campaign) {
    Logger.debug('Logging event on backend', 'analytics')
    REST.post(
      'campaign/event',
      {
        campaign: campaign,
        uid: Analytics.userIdentifier(),
        event: eventName,
        url: Window.get(['location', 'href']),
      },
      false
    ).catch(() => {
      return Logger.debug('Failure with backend logging', 'analytics')
    })
  }

  static fireFBPixel(eventName, campaigns, amount, additionalData) {
    // Retry pixel event if still not initialized
    if (PIXEL_STATUS === null) {
      Logger.debug(`Pixel not initalized yet. Retrying...`, 'analytics')
      return _.delay(Analytics.fireFBPixel, 500, eventName, campaigns, amount)
    }

    if (PIXEL_STATUS === false) {
      return Logger.debug(
        `Would have fired ${eventName} event but adblock is enabled`,
        'analytics'
      )
    }

    Analytics.fbq(
      'track',
      eventName,
      eventName === 'PageView'
        ? null
        : {
            currency: 'USD',
            value: amount,
            content_ids: campaigns,
          },
      additionalData
    )
  }

  static ViewPage() {
    const eventName = 'PageView'
    Analytics.fireFBPixel(eventName)
  }

  static ViewContent(data) {
    const eventName = 'ViewContent'
    Analytics.fireBackendTracking(eventName, data.campaigns[0])
    Analytics.fireFBPixel(eventName, data.campaigns, data.value)
  }

  static AddToCart(data) {
    const eventName = 'AddToCart'
    Analytics.fireBackendTracking(eventName, data.campaigns[0])
    Analytics.fireFBPixel(eventName, data.campaigns, data.value)
  }

  static InitiateCheckout(data) {
    const eventName = 'InitiateCheckout'
    Analytics.fireFBPixel(eventName, data.campaigns, data.value)
  }

  static Purchase(data) {
    const eventName = 'Purchase'
    Analytics.fireFBPixel(eventName, data.campaigns, data.value, {
      eventID: data.transactionID,
    })
  }
}
