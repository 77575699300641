import _ from 'lodash'

import Window from './window'
import Logger from './logger'
import Backend from '../backend/backend'

const queue = {}

// Debug purposes only
if (Backend.inDevMode) {
  if (process.env.GATSBY_BACKEND === 'local')
    Logger.debug('USING LOCAL BACKEND')
  else Logger.debug('USING LIVE BACKEND')
}

export default class REST {
  static abort() {
    _.forEach(queue, (request, key) => {
      request.abort()
      delete queue[key]
    })

    Logger.debug('All API calls aborted')
  }

  /**
        TODO check for Internet connection
    */
  static post(endpoint, data = {}, throwErrors = true) {
    const controller = new AbortController()
    const key = _.uniqueId()
    queue[key] = controller

    data.debug = process.env.GATSBY_BACKEND === 'local' ? true : false

    return fetch(Backend.backendURL + endpoint, {
      method: 'post',
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
      signal: controller.signal,
    })
      .then(response => {
        delete queue[key]
        if (response.status !== 200) throw response

        return response.json()
      })
      .catch(error => {
        delete queue[key]
        if (throwErrors) {
          Window.alert(
            `Database failure: ${error.status} (${error.statusText})`,
            {
              variant: 'error',
            }
          )
        }
        throw error
      })
  }

  /**
   * Takes provided data and returns it within a promise.
   * Easy way to build skeleton code that can later be replaced with real Promise's
   */
  static promisify(data) {
    return new Promise(resolve => resolve(data))
  }
}
