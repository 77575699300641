/*
	eslint no-console: ["error", { allow: ["log"] }]
*/
import _ from 'lodash'
import moment from 'moment'

import Backend from '../backend/backend'

const keys = {}

export default class Logger {
  /**
		Prints to the console only if dev mode is enabled
	*/
  static debug(msg, category) {
    if (!Backend.inDevMode) return

    let prefix = ''
    if (category) {
      prefix = `[${_.toUpper(category)}] `
    }
    // eslint-disable-next-line no-console
    console.info(`${prefix}${msg}`)
  }

  /**
    	Allow events to be timed.
    	Output results in dev mode or log in production mode.
     */
  static measure(key, msg = null, stopMeasuring = false) {
    const now = moment()

    // Key is untracked; begin tracking
    if (!_.has(keys, key)) {
      _.set(keys, [key], {
        start: now,
        step: null,
      })
    }

    // Create debug statements only if necessary
    if (Backend.inDevMode) {
      // Set intermediate time
      _.set(keys, [key, 'step'], now)

      // Track time since last call
      const delta = now.diff(_.get(keys, [key, 'step']))

      if (stopMeasuring === false)
        Logger.debug(`${key}: ${msg} in ${delta} ms.`)
    }

    if (stopMeasuring) {
      const totalTime = now.diff(_.get(keys, [key, 'start']))

      Logger.debug(`${key}: Total time was ${totalTime} ms.`)

      // TODO log to Facebook

      _.unset(keys, [key])
    }
  }
}
